<template>
  <div class="main_content align-items-center" style="min-height: 80vh">
    <div class="row justify-content-center">
      <div class="row justify-content-between mb_54">
        <div class="col-md-12 col-lg-6 top_heading">
          <div class="heading">
            <h2 style="color:#39353D;">My Reports </h2>
            <h3 style="color:#39353D;">Employee Dashboard</h3>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 mt-md-0 mt-2">
          <div class="d-flex align-items-center justify-content-end">
            <div class="date_toolbar_main">
              <div class="bg-white date-toolbar">
                <div class="row">
                  <div class="col-12 align-self-center">
                    <div class="row">
                      <div class="col-12 col-lg-6">
                        <div class="date">
                          <div class="heading_5">From</div>
                          <div class=" text_black font_size_14 d-flex align-items-center ">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5" viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)"
                                  style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                              </svg></span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.start_date"
                              :format="customFormatter" @selected="changeStartDate" :disabledDates="disabledFutureDates">
                            </datepicker>
                            <p class="min-date-value d-none">
                              {{ listConfig.start_date | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="col-12 col-lg-6">
                        <div class="date todate pl_6 border-0">
                          <div class="heading_5">To</div>
                          <div class=" text_black font_size_14 d-flex align-items-center ">
                            <span><svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="17.5"
                                viewBox="0 0 17.5 17.5">
                                <path data-name="Path 131"
                                  d="M7.444 6.556V3m7.111 3.556V3m-8 7.111h8.889M4.778 19h12.444A1.778 1.778 0 0 0 19 17.222V6.556a1.778 1.778 0 0 0-1.778-1.778H4.778A1.778 1.778 0 0 0 3 6.556v10.666A1.778 1.778 0 0 0 4.778 19z"
                                  transform="translate(-2.25 -2.25)"
                                  style=" fill: none; stroke-linecap: round; stroke-linejoin: round; stroke-width: 1.5px; " />
                              </svg></span>
                            <datepicker placeholder="MM DD, YYYY" v-model="listConfig.end_date" :format="customFormatter"
                              @selected="changeEndDate" :disabled="isDisabled" :disabledDates="disabledDates">
                            </datepicker>
                            <p class="max-date-value d-none">
                              {{ listConfig.end_date | formatDate }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript:void(0)" class="reset_btn" @click="resetDates">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" x="0px"
                y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve">
                <metadata> Svg Vector Icons : http://www.onlinewebfonts.com/icon </metadata>
                <g>
                  <path
                    d="M829.7,134.7C740.5,59,624.7,10,500,10C228.3,10,10,228.3,10,500h89.1C99.1,277.3,277.3,99.1,500,99.1c102.5,0,196,35.7,262.8,98l-75.7,71.3l267.3,49L914.3,50.1L829.7,134.7z M179.3,869.7C268.3,945.5,379.7,990,500,990c271.7,0,490-218.3,490-490h-89.1c0,222.7-178.2,400.9-400.9,400.9c-98,0-187.1-35.7-253.9-89.1l84.7-80.2l-267.3-49l40.1,267.3L179.3,869.7z">
                  </path>
                </g>
              </svg>
            </a>
          </div>
        </div>
      </div>

      <div class="row" v-if="checkReportType == 3">
        <div class="col-md-12 mb-3">
          <div class="alche_btn tab_report toggle_switch list_card_switch me-0 my-2">
            <ul id="myTab" role="tablist" class="nav nav-tabs list_tabs btn-reports">
              <li role="presentation" class="nav-item list-item">
                <button id="percent-tab" @click="getReports('alchemer')" data-bs-toggle="tab" data-bs-target="#percent"
                  type="button" role="tab" aria-controls="percent" aria-selected="true" class="nav-link list-link active">
                  Current Reports
                </button>
              </li>
              <li role="presentation" class="nav-item list-item">
                <button id="hash-tab" @click="getReports('qualtrics')" data-bs-toggle="tab" data-bs-target="#hash"
                  type="button" role="tab" aria-controls="hash" aria-selected="false" class="nav-link list-link">
                  Previous Reports
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div id="myTabContent" class="tab-content ">
            <div id="percent" role="tabpanel" aria-labelledby="#percent"
              :class="checkReportType != 2 ? 'tab-pane fade active show' : 'tab-pane fade'">
              <!-- Alchemer Reports -->
              <div class="row">
                <div class="col-md-12">
                  <div class="table_card">
                    <div class="row justify-content-end align-items-center pb_40">
                      <div class="col-md-12 col-xl-12 col-xxl-12">
                        <div class="align-left ">
                          <h3 class="card_heading" v-if="checkReportType == 1">My Reports</h3>
                          <h3 class="card_heading" v-else>Current Reports</h3>
                        </div>
                      </div>
                    </div>
                    <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                      <div class="dataTables_length mb-3 mb-lg-0" id="datatable_length">
                        <label>Reports
                          <select name="datatable_length" aria-controls="datatable" class="" v-model.number="itemsPerPage"
                            @change="getProductsBysearch">
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                          </select>
                          entries</label>
                      </div>
                      <div id="datatable_filter" class="dataTables_filter">
                        <label>Search :<input type="search" class="" placeholder="Type Of Assessment"
                            aria-controls="datatable" v-model="listConfig.search_string"
                            v-on:keyup.enter="getProductsBysearch" />
                          <a v-if="listConfig.search_string" href="javascript:void(0)" @click="getProductsBysearch"
                            class="search_icon slty_up">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                              viewBox="0 0 16 16">
                              <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </a>
                          <a href="javascript:void(0)" v-if="listConfig.search_string"
                            @click="listConfig.search_string = null; getProductsBysearch()" class="crossicon x_icon">
                            X
                          </a>
                        </label>
                      </div>
                    </div>
                    <div class="table-responsive table_audit_log">
                      <table class="table table-bordered table dataTable no-footer">
                        <thead>
                          <tr>
                            <th class="sort DOA">
                              <SortIcons :lable="'Date Of Assessment  '" :listConfig="listConfig" :sortBy="'created_at'"
                                @sorting="sorting($event)" />
                            </th>
                            <th class="d-none"> Name</th>
                            <th class="d-none">Email Address</th>
                            <th class="sort DOA">
                              <SortIcons :lable="'Type Of Assessment'" :listConfig="listConfig"
                                :sortBy="'type_of_assessment'" @sorting="sorting($event)" />
                            </th>
                            <th class="sort d-none">
                              <SortIcons :lable="'Created Date'" :listConfig="listConfig" :sortBy="'created_at'"
                                @sorting="sorting($event)" />
                            </th>
                            <th class="report_action">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(report, indx) in reports.reports" :key="indx">
                            <td>
                              <p>{{ report.date }}</p>
                            </td>
                            <td class="d-none">
                              <p>{{ first_name }}</p>
                            </td>
                            <td class="d-none">
                              <p class="email_tag"> <a :href="'mailto:' + report.user_email">{{ report.user_email }}</a>
                              </p>
                            </td>
                            <td>
                              <p> {{ report.type_of_assessment ? report.type_of_assessment : "Not Available" }} </p>
                            </td>
                            <td class="d-none">
                              <p>{{ report.date }}</p>
                            </td>
                            <td>
                              <div class="edit_btn">
                                <p>
                                  <router-link :to="{ name: 'view_alchemer_reports', params: { url: report.u_id }, }"
                                    target="_blank" title="View Report">
                                    <img class="edit-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                      alt="img" />
                                  </router-link>
                                  &nbsp;
                                  <a :href="'javascript:void(0)'" @click="downloadPdfReport(report.summary_report,'My Reports',report.full_name)"
                                    title="Download">
                                    <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')"
                                      alt="img" />
                                  </a>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="data_length == 0">
                            <td colspan="5" class="text-center">No Records found</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="float-lg-start text-sm-center">{{ positionText }}</p>
                    <VPagination v-if="data_length" class="dataTables_paginate sml_size_paginate"
                      :totalPages="pages.length" :perPage="itemsPerPage" :currentPage.sync="currentPage"
                      @pagechanged="onPageChange" />
                  </div>
                </div>
              </div>
              <!-- Recommended Videos -->
              <div class="first_section follow_ups recommneded_video">
                <div class="row">
                  <div class="col-md-12 video_slider pt_40">
                    <h4 class="font_size_25 secondary_heading mb_30">Recommended Videos</h4>
                    <div v-if="video_data.length <= 0">
                      <h5 style="text-align:center;font-size:20px;margin:20px;">No Recommended Video Found</h5>
                    </div>
                    <div v-else>
                      <carousel :loop="false" :nav="true" :dots="false" :margin="0"
                        class="position-relative owl-slider custom_report_crousel" :rewind="false"
                        :responsive="{ 0: { items: 1 }, 768: { items: 1 }, 1280: { items: 3 }, 1400: { items: 4 } }">
                        <a v-for="(videoData, inx) in video_data" :key="inx" href="javascript:void(0)" class="video_card"
                          data-bs-toggle="modal" data-bs-target="#exampleModal"
                          @click="createVideoURL = videoData.video_url">
                          <div class="image">
                            <iframe width="100%" height="100%"
                              :src="videoData.video_url + '?autoplay=0&showinfo=0&controls=0'" frameborder="0"></iframe>
                            <a href="javascript:void(0)" data-bs-toggle="modal" data-bs-target="#exampleModal"
                              @click="createVideoURL = videoData.video_url" class="overlay_anchor"></a>
                          </div>
                          <div class="content">
                            <h5 class="font_size_16 secondary_color d-flex align-items-center">
                              <svg xmlns="http://www.w3.org/2000/svg" width="22.414" height="22.414"
                                viewBox="0 0 22.414 22.414">
                                <path id="Path_9465" data-name="Path 9465"
                                  d="M35.207,24A11.207,11.207,0,1,0,46.414,35.207,11.207,11.207,0,0,0,35.207,24Zm3.922,11.929-5.172,3.448a.938.938,0,0,1-.474.14.916.916,0,0,1-.409-.1.862.862,0,0,1-.453-.765v-6.9a.862.862,0,0,1,.453-.765.894.894,0,0,1,.884.043l5.172,3.448a.862.862,0,0,1,0,1.444Z"
                                  transform="translate(-24 -24)" />
                              </svg>
                              {{ videoData.video_title }}
                            </h5>
                          </div>
                        </a>
                      </carousel>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Equipment Recommendations -->
              <div class="table_admin mt_20">
                <div class="col-md-12">
                  <div class="table_card">
                    <div class="row justify-content-end align-items-center pb_40">
                      <div class="col-md-12 col-xl-12 col-xxl-12">
                        <div class="align-left ">
                          <h3 class="card_heading">Equipment Recommendations</h3>
                        </div>
                      </div>
                    </div>
                    <div id="datatable_wrapper_equip" class="dataTables_wrapper no-footer">
                      <div class="dataTables_length mb-3 mb-lg-0" id="datatable_length_equip">
                        <label>Reports
                          <select name="datatable_length_equip" aria-controls="datatable" class=""
                            v-model.number="itemsPerPage_equip" @change="getProductsBysearchEquip">
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                          </select>
                          entries</label>
                      </div>
                      <div id="datatable_filter_equip" class="dataTables_filter">
                        <label>Search :<input type="search" class="" placeholder="" aria-controls="datatable"
                            v-model="listConfig_equip.search_string" v-on:keyup.enter="getProductsBysearchEquip" />
                          <a v-if="listConfig_equip.search_string" href="javascript:void(0)"
                            @click="getProductsBysearchEquip" class="search_icon slty_up">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                              viewBox="0 0 16 16">
                              <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </a>
                          <a href="javascript:void(0)" v-if="listConfig_equip.search_string"
                            @click="listConfig_equip.search_string = null; getProductsBysearchEquip()"
                            class="crossicon x_icon">
                            X
                          </a>
                        </label>
                      </div>
                    </div>
                    <div class="table-responsive table_audit_log">
                      <table class="table table-bordered table dataTable no-footer">
                        <thead>
                          <tr>
                            <th class="sort DOA">
                              <SortIcons :lable="'Date Of Assessment  '" :listConfig="listConfig_equip"
                                :sortBy="'assessment_date'" @sorting="sorting_equip($event)" />
                            </th>
                            <th class="sort Eq_recommanded">
                              <SortIcons :lable="'Equipment Recommended'" :listConfig="listConfig_equip"
                                :sortBy="'equipment_name'" @sorting="sorting_equip($event)" />
                            </th>
                            <th class="sort DOA">
                              <SortIcons :lable="'Type of Assessment'" :listConfig="listConfig_equip"
                                :sortBy="'type_of_assessment'" @sorting="sorting_equip($event)" />
                            </th>
                            <th class="report_action">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(equipData, indx) in equip_data" :key="indx">
                            <td>
                              <p class="">{{ equipData.formated_assessment_date }}</p>
                              <!--<p>{{ equipData.created_at }}</p>-->
                            </td>
                            <td>
                              <p>{{ equipData.equipment_name }}</p>
                            </td>
                            <td>
                              <p>{{ equipData.type_of_assessment ? equipData.type_of_assessment : "Not Available" }}</p>
                            </td>
                            <td>
                              <router-link
                                :to="{ name: 'view_alchemer_reports', params: { url: equipData.report_u_id }, }"
                                target="_blank" title="View Report" v-if="!equipData.choice_url">
                                <img class="edit-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                  alt="img" />
                              </router-link>
                              <div class="edit_btn" v-else>
                                <button @click="equipChoice(equipData.choice, equipData.choice_url)" title="View Image"
                                  data-bs-toggle="modal" data-bs-target="#choiceModal"
                                  style="border:0;background:transparent">
                                  <img class="edit-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                    alt="img" />
                                </button>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="data_length_equip == 0">
                            <td colspan="5" class="text-center">No Records found</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="float-lg-start text-sm-center">{{ positionTextEquip }}</p>
                    <VPagination v-if="data_length_equip" class="dataTables_paginate" :totalPages="pages_equip.length"
                      :perPage="itemsPerPage_equip" :currentPage.sync="currentPage_equip"
                      @pagechanged="onPageChangeEquip" />
                  </div>
                </div>
              </div>
              <!-- Quick Fixes -->
              <div class="table_admin mt_20">
                <div class="col-md-12">
                  <div class="table_card quick_fixes_wrap">
                    <div class="row justify-content-end align-items-center pb_40">
                      <div class="col-md-12 col-xl-12 col-xxl-12">
                        <div class="align-left ">
                          <h3 class="card_heading">Quick Fixes</h3>
                        </div>
                      </div>
                    </div>
                    <div id="datatable_wrapper_fixes" class="dataTables_wrapper no-footer">
                      <div class="dataTables_length mb-3 mb-lg-0" id="datatable_length_fixes">
                        <label>Reports
                          <select name="datatable_length_fixes" aria-controls="datatable" class=""
                            v-model.number="itemsPerPage_fixes" @change="getProductsBysearchFixes">
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                          </select>
                          entries</label>
                      </div>
                      <div id="datatable_filter_fixes" class="dataTables_filter">
                        <label>Search :<input type="search" class="" placeholder="" aria-controls="datatable"
                            v-model="listConfig_fixes.search_string" v-on:keyup.enter="getProductsBysearchFixes" />
                          <a v-if="listConfig_fixes.search_string" href="javascript:void(0)"
                            @click="getProductsBysearchFixes" class="search_icon slty_up">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                              viewBox="0 0 16 16">
                              <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </a>
                          <a href="javascript:void(0)" v-if="listConfig_fixes.search_string"
                            @click="listConfig_fixes.search_string = null; getProductsBysearchFixes()"
                            class="crossicon x_icon">
                            X
                          </a>
                        </label>
                      </div>
                    </div>
                    <div class="table-responsive table_audit_log">
                      <table class="table table-bordered table dataTable no-footer">
                        <thead>
                          <tr>
                            <th class="sort DOA" style="width:20% !important;">
                              <SortIcons :lable="'Date Of Assessment'" :listConfig="listConfig_fixes"
                                :sortBy="'assessment_date'" @sorting="sorting_fixes($event)" />
                            </th>
                            <th class="sort" style="width:20% !important; min-width: 200px !important;">
                              <SortIcons :lable="'Quick Fixes'" :listConfig="listConfig_fixes" :sortBy="'fixes_name'"
                                @sorting="sorting_fixes($event)" />
                            </th>
                            <th style="width:40% !important;min-width: 350px;">Details</th>
                            <th style="width:20% !important;">Type of Assessment</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(fixesData, indx) in fixes_data" :key="indx">
                            <td style="width:20% !important;">
                              <p class="">{{ fixesData.formated_assessment_date }}</p>
                            </td>
                            <td style="width:20% !important;">
                              <p>{{ fixesData.fixes_name }}</p>
                            </td>
                            <td style="width:40% !important;">
                              <p v-html="fixesData.fixes_details"></p>
                            </td>
                            <td style="width:20% !important;">
                              <p>{{ fixesData.type_of_assessment ? fixesData.type_of_assessment : "Not Available" }}</p>
                            </td>
                          </tr>
                          <tr v-if="data_length_fixes == 0">
                            <td colspan="4" class="text-center">No Records found</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="float-lg-start text-sm-center">{{ positionTextFixes }}</p>
                    <VPagination v-if="data_length_fixes" class="dataTables_paginate" :totalPages="pages_fixes.length"
                      :perPage="itemsPerPage_fixes" :currentPage.sync="currentPage_fixes"
                      @pagechanged="onPageChangeFixes" />
                  </div>
                </div>
              </div>
            </div>
            <div id="hash" role="tabpanel" aria-labelledby="#hash"
              :class="checkReportType == 2 ? 'tab-pane fade active show' : 'tab-pane fade'">
              <!-- Qualtrics Reports -->
              <div class="row">
                <div class="col-md-12">
                  <div class="table_card">
                    <div class="row justify-content-end align-items-center pb_40">
                      <div class="col-md-12 col-xl-12 col-xxl-12">
                        <div class="align-left ">
                          <h3 class="card_heading" v-if="checkReportType == 2">My Reports</h3>
                          <h3 class="card_heading" v-else>Previous Reports</h3>
                        </div>
                      </div>
                    </div>
                    <div id="datatable_wrapper" class="dataTables_wrapper no-footer">
                      <div class="dataTables_length mb-3 mb-lg-0" id="datatable_length">
                        <label>Reports
                          <select name="datatable_length" aria-controls="datatable" class=""
                            v-model.number="itemsPerPage_qual" @change="getProductsBysearchQual">
                            <option>10</option>
                            <option>25</option>
                            <option>50</option>
                            <option>100</option>
                            <option>500</option>
                          </select>
                          entries</label>
                      </div>
                      <div id="datatable_filter" class="dataTables_filter">
                        <label>Search :<input type="search" class="" placeholder="Type Of Assessment"
                            aria-controls="datatable" v-model="listConfig_qual.search_string"
                            v-on:keyup.enter="getProductsBysearchQual" />
                          <a v-if="listConfig_qual.search_string" href="javascript:void(0)"
                            @click="getProductsBysearchQual" class="search_icon slty_up"> <svg
                              xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="#00" class="bi bi-search"
                              viewBox="0 0 16 16">
                              <path
                                d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </a>
                          <a href="javascript:void(0)" v-if="listConfig_qual.search_string"
                            @click="listConfig_qual.search_string = null; getProductsBysearchQual(); "
                            class="crossicon x_icon">
                            X
                          </a>
                        </label>
                      </div>
                    </div>
                    <div class="table-responsive table_audit_log">
                      <table class="table table-bordered table dataTable no-footer">
                        <thead>
                          <tr>
                            <th class="sort DOA">
                              <SortIcons :lable="'Date Of Assessment  '" :listConfig="listConfig_qual"
                                :sortBy="'assessment_date'" @sorting="sorting_qual($event)" />
                            </th>
                            <!-- <th class="report_name">Name</th> -->
                            <!-- <th>Email Address</th> -->
                            <th class="sort DOA">
                              <SortIcons :lable="'Type Of Assessment'" :listConfig="listConfig_qual"
                                :sortBy="'type_of_assessment'" @sorting="sorting_qual($event)" />
                            </th>
                            <th class="report_action">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(report, indx) in reports_qual.records" :key="indx">
                            <td>
                              <p> {{ report.formated_date ? report.formated_date : report.formated_assessment_date }} </p>
                            </td>
                            <!-- <td> <p>{{ first_name }}</p> </td> -->
                            <!-- <td> <p class="email_tag"> <a :href="'mailto:' + report.user_email">{{ report.user_email }}</a> </p> </td> -->
                            <td>
                              <p> {{ report.type_of_assessment ? report.type_of_assessment : "Not Available" }} </p>
                            </td>
                            <td>
                              <div class="edit_btn">
                                <p>
                                  <router-link :to="{ name: 'view_my_reports', params: { url: report.u_id }, }"
                                    target="_blank" title="View Report">
                                    <img class="edit-icon" :src="require('@/assets/images/dashboard/View_Icon.svg')"
                                      alt="img" />
                                  </router-link>
                                  &nbsp;
                                  <a :href="'javascript:void(0)'"
                                    @click="downloadZipFile(report.summary_report, first_name, report.type_of_assessment)"
                                    title="Download">
                                    <img class="edit-icon" :src="require('@/assets/images/dashboard/download.svg')"
                                      alt="img" />
                                  </a>
                                </p>
                              </div>
                            </td>
                          </tr>
                          <tr v-if="data_length_qual == 0">
                            <td colspan="3" class="text-center">No Records found</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <p class="float-lg-start text-sm-center">{{ positionTextQual }}</p>
                    <VPagination v-if="data_length_qual" class="dataTables_paginate" :totalPages="pages_qual.length"
                      :perPage="itemsPerPage_qual" :currentPage.sync="currentPage_qual" @pagechanged="onPageChangeQual" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Recommended Videos View Pop-Up -->
      <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-end">
                <a href="javascript:void(0)" class="modal_close" data-bs-dismiss="modal" aria-label="Close"
                  @click="hideModal5">
                  <img :src="require('@/assets/images/close_modal.png')" />
                </a>
              </div>
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe id="closed5" :src="createVideoURL" allowfullscreen frameborder="0"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Equipment Recommendations View Icon Pop-Up -->
      <div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="choiceModal" tabindex="-1"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-body">
              <div class="text-end">
                <a href="javascript:void(0)" class="modal_close" data-bs-dismiss="modal" aria-label="Close">
                  <img :src="require('@/assets/images/close_modal.png')" />
                </a>
              </div>
              <div class="model-body">
                <div class="mt-5 text-center" v-if="choice_url">
                  <img :src="choice_url" width="300" class="img-fluid">
                  <h3 class="mb-5 mt_20 mx-auto w-75">{{ choice }}</h3>
                </div>
                <div class="mt-5 text-center" v-else>
                  <h3 class="mb-5 mt_20 mx-auto w-75 no_data p-5">No image to display</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonFunction from '../../mixin/commonFunction'
import carousel from "vue-owl-carousel";
import "vue-select/dist/vue-select.css";
import AuthService from "@/services/AuthService";
import $ from "jquery";
import moment from "moment";
import Datepicker from "vuejs-datepicker";
import VPagination from "@/components/VPagination";
import SortIcons from "@/components/SortingIcons";

export default {
  name: "alchemer_reports",
  mixins: [commonFunction],
  components: {
    carousel,
    Datepicker,
    VPagination,
    SortIcons,
  },
  data() {
    return {
      show: false,
      reportAvailable: true,
      first_name: "",
      createVideoURL: "",
      reports: [],
      reports_qual: [],
      video_data: [],
      equip_data: [],
      fixes_data: [],
      isDisabled: true,
      disabledDates: {
        to: new Date(Date.now() - 8640000),
      },
      disabledFutureDates: {
        from: new Date(Date.now()),
      },
      currentPage: 1,
      itemsPerPage: 10,
      listConfig: {
        offset: (this.currentPage - 1) * this.itemsPerPage,
        limit: this.itemsPerPage,
        sortBy: "created_at",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      data_length: 0,

      currentPage_qual: 1,
      itemsPerPage_qual: 10,
      listConfig_qual: {
        offset: (this.currentPage_qual - 1) * this.itemsPerPage_qual,
        limit: this.itemsPerPage_qual,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date: null,
        end_date: null,
      },
      data_length_qual: 0,

      currentPage_equip: 1,
      itemsPerPage_equip: 10,
      listConfig_equip: {
        offset: (this.currentPage_equip - 1) * this.itemsPerPage_equip,
        limit: this.itemsPerPage_equip,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date_equip: null,
        end_date_equip: null,
      },
      data_length_equip: 0,

      currentPage_fixes: 1,
      itemsPerPage_fixes: 10,
      listConfig_fixes: {
        offset: (this.currentPage_fixes - 1) * this.itemsPerPage_fixes,
        limit: this.itemsPerPage_fixes,
        sortBy: "assessment_date",
        order: "DESC",
        search_string: null,
        start_date_fixes: null,
        end_date_fixes: null,
      },
      data_length_fixes: 0,
      choice: "",
      choice_url: "",
      checkReportType: '3',
      reportType: 'alchemer',
      previousStartDate: null,
    };
  },
  computed: {
    positionText: function () {
      var endIndex = this.currentPage * this.itemsPerPage,
        startIndex = (this.currentPage - 1) * this.itemsPerPage + 1;
      if (this.data_length == 0) {
        startIndex = 0;
      }
      return (
        "Showing " +
        startIndex +
        " to " +
        (endIndex > this.data_length ? this.data_length : endIndex) +
        " of " +
        this.data_length
      );
    },
    positionTextQual: function () {
      var endIndex_qual = this.currentPage_qual * this.itemsPerPage_qual,
        startIndex_qual = (this.currentPage_qual - 1) * this.itemsPerPage_qual + 1;
      if (this.data_length_qual == 0) {
        startIndex_qual = 0;
      }
      return (
        "Showing " +
        startIndex_qual +
        " to " +
        (endIndex_qual > this.data_length_qual ? this.data_length_qual : endIndex_qual) +
        " of " +
        this.data_length_qual
      );
    },
    positionTextEquip: function () {
      var endIndex_equip = this.currentPage_equip * this.itemsPerPage_equip,
        startIndex_equip = (this.currentPage_equip - 1) * this.itemsPerPage_equip + 1;
      if (this.data_length_equip == 0) {
        startIndex_equip = 0;
      }
      return (
        "Showing " +
        startIndex_equip +
        " to " +
        (endIndex_equip > this.data_length_equip ? this.data_length_equip : endIndex_equip) +
        " of " +
        this.data_length_equip
      );
    },
    positionTextFixes: function () {
      var endIndex_fixes = this.currentPage_fixes * this.itemsPerPage_fixes,
        startIndex_fixes = (this.currentPage_fixes - 1) * this.itemsPerPage_fixes + 1;
      if (this.data_length_fixes == 0) {
        startIndex_fixes = 0;
      }
      return (
        "Showing " +
        startIndex_fixes +
        " to " +
        (endIndex_fixes > this.data_length_fixes ? this.data_length_fixes : endIndex_fixes) +
        " of " +
        this.data_length_fixes
      );
    },
    btnText: function () {
      if (this.show) {
        return "";
      }
      return "";
    },
    pages() {
      if (this.data_length <= this.itemsPerPage) {
        return [1];
      }
      let pages_array = [
        ...Array(Math.ceil(this.data_length / this.itemsPerPage)).keys(),
      ].map((e) => e + 1);
      return pages_array;
    },
    pages_qual() {
      if (this.data_length_qual <= this.itemsPerPage_qual) {
        return [1];
      }
      let pages_array_qual = [
        ...Array(Math.ceil(this.data_length_qual / this.itemsPerPage_qual)).keys(),
      ].map((e) => e + 1);
      return pages_array_qual;
    },
    pages_equip() {
      if (this.data_length_equip <= this.itemsPerPage_equip) {
        return [1];
      }
      let pages_array_equip = [
        ...Array(Math.ceil(this.data_length_equip / this.itemsPerPage_equip)).keys(),
      ].map((e) => e + 1);
      return pages_array_equip;
    },
    pages_fixes() {
      if (this.data_length_fixes <= this.itemsPerPage_fixes) {
        return [1];
      }
      let pages_array_fixes = [
        ...Array(Math.ceil(this.data_length_fixes / this.itemsPerPage_fixes)).keys(),
      ].map((e) => e + 1);
      return pages_array_fixes;
    },
  },
  watch: {
    currentPage(newValue) {
      this.listConfig.offset = (newValue - 1) * this.itemsPerPage;
      this.getSingleUserReport();
    },
    currentPage_qual(newValue_qual) {
      this.listConfig_qual.offset = (newValue_qual - 1) * this.itemsPerPage_qual;
      this.getQualData();
    },
    currentPage_equip(newValue_equip) {
      this.listConfig_equip.offset = (newValue_equip - 1) * this.itemsPerPage_equip;
      this.getEquipData();
    },
    currentPage_fixes(newValue_fixes) {
      this.listConfig_fixes.offset = (newValue_fixes - 1) * this.itemsPerPage_fixes;
      this.getFixesData();
    },
  },
  destroyed() {
    this.$parent.employeeDashboard = false;
  },
  mounted() {
    document.title = "PBErgo - " + this.$router.currentRoute.meta.page_title;
    this.listConfig = {
      offset: (this.currentPage - 1) * this.itemsPerPage,
      limit: this.itemsPerPage,
      sortBy: "created_at",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
    };
    this.listConfig_qual = {
      offset: (this.currentPage_qual - 1) * this.itemsPerPage_qual,
      limit: this.itemsPerPage_qual,
      sortBy: "assessment_date",
      order: "DESC",
      search_string: null,
      start_date: null,
      end_date: null,
    };
    this.listConfig_equip = {
      offset: (this.currentPage_equip - 1) * this.itemsPerPage_equip,
      limit: this.itemsPerPage_equip,
      sortBy: "assessment_date",
      order: "DESC",
      search_string: null,
      start_date_equip: null,
      end_date_equip: null,
    };
    this.listConfig_fixes = {
      offset: (this.currentPage_fixes - 1) * this.itemsPerPage_fixes,
      limit: this.itemsPerPage_fixes,
      sortBy: "assessment_date",
      order: "DESC",
      search_string: null,
      start_date_fixes: null,
      end_date_fixes: null,
    };
    this.$parent.employeeDashboard = true;
    var clientData = this.$store.getters["auth/authUser"];
    if (clientData.client.report_type == 1) {
      this.checkReportType = '1';
      this.getReports('alchemer');
    } else if (clientData.client.report_type == 2) {
      this.checkReportType = '2';
      this.getReports('qualtrics');
    } else {
      this.checkReportType = '3';
      this.getReports('alchemer');
    }
  },
  methods: {
    getReports(repoType) {
      this.reportType = repoType;
      this.listConfig.end_date = '';
      this.listConfig.start_date = '';
      if (repoType == 'alchemer') {
        this.listConfig.search_string = null;
        this.listConfig_equip.search_string = null;
        this.listConfig_fixes.search_string = null;
        this.getVideoData();
        this.getProductsBysearch();
        this.getProductsBysearchEquip();
        this.getProductsBysearchFixes();
      }
      else if (repoType == 'qualtrics') {
        this.listConfig_qual.search_string = null;
        this.getProductsBysearchQual();
      }
    },
    resetDates() {
      this.listConfig.end_date = '';
      this.listConfig.start_date = '';
      this.isDisabled = true;
      if (this.reportType == 'alchemer') {
        this.getProductsBysearch();
        this.getProductsBysearchEquip();
        this.getProductsBysearchFixes();
      }
      else if (this.reportType == 'qualtrics') {
        this.getProductsBysearchQual();
      }
    },
    hideModal5() {
      // this.$emit('hide');
      document.getElementById("closed5").src += "";
    },
    onPageChange(page) {
      this.currentPage = page;
    },
    onPageChangeQual(pages) {
      this.currentPage_qual = pages;
    },
    onPageChangeEquip(pages) {
      this.currentPage_equip = pages;
    },
    onPageChangeFixes(pages) {
      this.currentPage_fixes = pages;
    },
    sorting($event) {
      this.listConfig.sortBy == $event.sortBy;
      this.listConfig.order == $event.order;
      this.getSingleUserReport();
    },
    sorting_qual($event) {
      this.listConfig_qual.sortBy == $event.sortBy;
      this.listConfig_qual.order == $event.order;
      this.getQualData();
    },
    sorting_equip($event) {
      this.listConfig_equip.sortBy == $event.sortBy;
      this.listConfig_equip.order == $event.order;
      this.getEquipData();
    },
    sorting_fixes($event) {
      this.listConfig_fixes.sortBy == $event.sortBy;
      this.listConfig_fixes.order == $event.order;
      this.getFixesData();
    },
    getProductsBysearch() {
      this.currentPage = 1;
      this.listConfig.limit = this.itemsPerPage;
      this.getSingleUserReport();
    },
    getProductsBysearchQual() {
      this.currentPage_qual = 1;
      this.listConfig_qual.limit = this.itemsPerPage_qual;
      this.listConfig_qual.start_date = this.listConfig.start_date;
      this.listConfig_qual.end_date = this.listConfig.end_date;
      this.getQualData();
    },
    getProductsBysearchEquip() {
      this.currentPage_equip = 1;
      this.listConfig_equip.limit = this.itemsPerPage_equip;
      this.listConfig_equip.start_date_equip = this.listConfig.start_date;
      this.listConfig_equip.end_date_equip = this.listConfig.end_date;
      this.getEquipData();
    },
    getProductsBysearchFixes() {
      this.currentPage_fixes = 1;
      this.listConfig_fixes.limit = this.itemsPerPage_fixes;
      this.listConfig_fixes.start_date_fixes = this.listConfig.start_date;
      this.listConfig_fixes.end_date_fixes = this.listConfig.end_date;
      this.getFixesData();
    },
    getVideoData() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "get",
        url: process.env.VUE_APP_API_URL + "/api/user-video-recommendation",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: _this.listConfig,
      };
      _this.axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.video_data = decodedJson.recommended_videos;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.data.type == false) {
            _this.$router.push({
              name: "login"
            });
          }
        });
    },
    getEquipData() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/user-equipment-recommendation",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig_equip) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.equip_data = decodedJson.user_equipment;
          _this.data_length_equip = decodedJson.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.data.type == false) {
            _this.$router.push({ name: "login" });
          }
        });
    },
    getFixesData() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/user-quick-fixes",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig_fixes) },
      };
      _this
        .axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.fixes_data = decodedJson.user_quickFixes;
          _this.data_length_fixes = decodedJson.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.data.type == false) {
            _this.$router.push({ name: "login" });
          }
        });
    },
    getSingleUserReport() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/alchemer-report-pagination",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig) },
      };
      _this.axios(config)
        .then(({ data }) => {
          var decodedJson = _this.decodeAPIResponse(data.data);
          _this.first_name = decodedJson.first_name;
          _this.reports = decodedJson;
          _this.data_length = decodedJson.counts;
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 401) {
            _this.tokenExpired();
          } else{
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
          }
        });
    },
    getQualData() {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      _this.isLoader = true;
      let config = {
        method: "post",
        url: process.env.VUE_APP_API_URL + "/api/employee-report-pagination",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
        },
        data: { request_data: this.encodeAPIRequestWithImages(_this.listConfig_qual) },
      };
      _this.axios(config)
        .then(({ data }) => {
          if (data.success) {
            var decodedJson = _this.decodeAPIResponse(data.data);
            _this.first_name = decodedJson.first_name;
            _this.reports_qual = decodedJson;
            _this.data_length_qual = decodedJson.counts;
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          } else {
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
            _this.$toast.error(data.message, {
              position: "top-right",
              duration: 5000,
            });
          }
        })
        .catch(({ response }) => {
          _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          if (response.status == 401) {
            _this.tokenExpired();
          } else{
            _this.$toast.error(response.data.error, {
              position: "top-right",
              duration: 5000,
            });
          }
        });
    },
    async downloadItem(url, username) {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      AuthService.downloadZipFile(url, username).then((response) => {
        var blobObj = new Blob([response.data], { type: "text/html" });
        var blobURL = window.URL.createObjectURL(blobObj);
        var iframe = document.createElement('iframe'); //load content in an iframe to print later
        document.body.appendChild(iframe);

        iframe.style.display = 'none';
        iframe.src = blobURL;
        iframe.onload = function () {
          var dom = iframe.contentWindow.document;
          var iframeElements = dom.querySelectorAll('iframe');
          
          iframeElements.forEach(async (el) => {
            if(el.classList.contains('skiptranslate')==false){
              var parentofIframe = el.parentNode;
              var anchor = document.createElement('a');
              anchor.setAttribute('href', el.src);

              var thumbnailImg = document.createElement('img');
              thumbnailImg.setAttribute('height', 140);
              thumbnailImg.setAttribute('width', 250);

              thumbnailImg.setAttribute('src', process.env.VUE_APP_API_URL + '/videothumbnail.jpeg');
              anchor.appendChild(thumbnailImg);
              parentofIframe.replaceChild(anchor, el);
            }
          });
          var googleTranslator = dom.getElementById("google_translate_element");
          if (googleTranslator != null || googleTranslator != undefined) {
            googleTranslator.style.display = "none";
          }
          // dom.getElementById("google_translate_element").style.display = "none";
          var backToTop = dom.getElementsByClassName('arrow');
          if (backToTop.length > 0) {
            Array.from(backToTop).forEach((btt) => {
              btt.parentElement.style.display = "none";
            });
          }
          setTimeout(function () {
            iframe.focus();
            iframe.contentWindow.print();
            _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
          }, 3000);
        };
      });
    },
    getThumbnailUrl(videoUrl) {
      var resUrl;
      this.axios({
        method: "get",
        url: videoUrl,
        // url: 'https://vimeo.com/api/oembed.json?url='+el.src
      })
        .then(result => {
          resUrl = result.data.thumbnail_url;
        });
      return resUrl;
    },
    downloadZipFile(url, username, type_of_assessment) {
      var fileName = "report";
      if (type_of_assessment == "Self-Assessment") {
        fileName = username + "-self-assessment.html";
      } else if (type_of_assessment == "Ergo Eval") {
        fileName = username + "-ergo-eval.html";
      }
      AuthService.downloadZipFile(url, username).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.setAttribute("download", fileName);
        document.body.appendChild(fileLink);
        fileLink.click();
      });
    },
    downloadPDF(url, username, type_of_assessment) {
      var _this = this;
      _this.$parent.$refs.fullpageloader.is_full_page_loader = true;
      var fileName = "report";
      if (type_of_assessment == "Self-Assessment") {
        fileName = username + "-self-assessment.pdf";
      } else if (type_of_assessment == "Ergo Eval") {
        fileName = username + "-ergo-eval.pdf";
      }
      _this.axios({
        method: "get",
        url: url
      })
        .then(result => {
          var htmlData = result.data;
          // var requestData = {fileUrl : url, fileName: fileName,htmlData: htmlData};
          let config = {
            method: "post",
            url: process.env.VUE_APP_API_URL + "/api/get-summary-report-pdf-link",
            headers: {
              Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
            },
            data: { fileUrl: url, fileName: fileName, htmlData: htmlData },
          };
          _this.axios(config)
            .then(({ data }) => {
              var fileURL = data.report_url;
              var fileLink = document.createElement("a");
              fileLink.href = fileURL;
              fileLink.setAttribute("download", fileName);
              document.body.appendChild(fileLink);
              fileLink.click();
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              /*_this.$toast.success('Summary Report Downloaded successfully', {
                position: "top-right",
              });*/
            }).catch(({ response }) => {
              _this.$parent.$refs.fullpageloader.is_full_page_loader = false;
              _this.$toast.error(response.data.error, {
                position: "top-right",
              })
              if (response.data.type == false) {
                _this.$router.push({
                  name: "login"
                });
              }
            });
        })
        .catch(error => {
          console.error("error getting file", error);
        });
    },
    updateDatePicker() {
      // Extend dataTables search
      $.fn.dataTable.ext.search.push(function (settings, data) {
        var min = $(".min-date-value").html();
        var max = $(".max-date-value").html();
        var createdAt = data[0] || 0; // Our date column in the table
        if (
          min == "" ||
          max == "" ||
          (moment(createdAt).isSameOrAfter(min) &&
            moment(createdAt).isSameOrBefore(max))
        ) {
          return true;
        }
        return false;
      });
      if (this.reportType == 'alchemer') {
        this.getProductsBysearch();
        this.getProductsBysearchEquip();
        this.getProductsBysearchFixes();
      }
      else if (this.reportType == 'qualtrics') {
        this.getProductsBysearchQual();
      }
    },
    // changeStartDate() {
    //   this.$nextTick(() => {
    //     this.isDisabled = false;
    //     this.disabledDates = {
    //       to: new Date(this.listConfig.start_date - 8640000),
    //       from: new Date(Date.now()),
    //     };
    //     if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
    //       this.updateDatePicker();
    //     }
    //   });
    // },
    changeStartDate(newStartDate) {
      this.$nextTick(() => {
        const startDate = new Date(newStartDate);
        const endDate = this.listConfig.end_date ? new Date(this.listConfig.end_date) : null;

        if (endDate && startDate > endDate) {
          this.$toast.error('"From" date cannot be greater than the "To" date.', {
              position: "top-right",
              duration: 5000,
            });
          this.listConfig.start_date = this.previousStartDate;
        } else {
          this.previousStartDate = newStartDate;

          // Old changeStartDate code
          this.isDisabled = false;
          this.disabledDates = {
            to: new Date(this.listConfig.start_date - 8640000),
            from: new Date(Date.now()),
          };
          if (this.listConfig.end_date != "" && this.listConfig.end_date != null) {
            this.updateDatePicker();
          }
        }
      });
    },
    changeEndDate() {
      this.$nextTick(() => {
        this.updateDatePicker();
      });
    },
    customFormatter(date) {
      return moment(date).format("MMM DD, YYYY");
    },
    equipChoice(choice, choice_url) {
      this.choice = choice;
      this.choice_url = choice_url;
    }
  },
};

  // function reload(){
  //    if (window.location.href.substr(-2) !== "?r") {
  //         window.location = window.location.href + "?r";
  //     }
  // }
  // setTimeout(reload, 100);
</script>